<template>
    <div> 
        <div class="row">
            <div class="col-12">
                <button  class="btn btn-info float-right" @click.prevent="resetPage()">Re-start</button> 
                <h3 class="nk-block-title page-title">Irrigation Report</h3>
            </div>
        </div> 
        <div  v-if="!summaries.length"  class="row">
            <div class="col">
                
            </div>
            <div class="col-auto">
                <div class="card card-shadow">
                    <div class="card-inner">
                        <vue-csv-import
                        :headers="true"
                        v-model="baseData"
                        :autoMatchFields="true"
                        :autoMatchIgnoreCase="true"
                        :map-fields="['Date','Crop','Field','Lane','Pull Length','Boom','MM Applied','Boom Width','Reservoir','Pull Time','Area','MM/HA','Gallons Used']"
                        >
                         <template slot="next" slot-scope="{load}">
                            <button v-if="!baseData.length" class="btn btn-dark btn-block" @click.prevent="load">Import</button>
                            <button v-if="baseData.length" class="btn btn-success btn-block" @click.prevent="processIRData()">Process</button> 
                        </template>
                        </vue-csv-import> 
                    </div>
                </div>
            </div>
            <div class="col">

            </div>
        </div>
        <div  v-if="summaries.length"  class="row">
            <div class="col-12">
                <div class="alert-warning">
                    
                </div>
                <div class="card">
                    <div class="card-inner">
                        <div class="row">
                            <div class="col-8">
                                <div class="row">
                                    <div class="col-4">
                                        <h5>Sites</h5>
                                        <div> 
                                            <a-select
                                                mode="multiple"
                                                placeholder="Select Sites to include"
                                                :value="selectedSites"
                                                style="width: 100%;"
                                                @change="sitesChanged"
                                            >
                                                <a-select-option v-for="item in filteredSites" :key="item" :value="item">
                                                {{ item }}
                                                </a-select-option>
                                            </a-select>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <h5>Crops</h5>
                                        <div>
                                            <a-select
                                                mode="multiple"
                                                placeholder="Select Crops to include"
                                                :value="selectedCrops"
                                                style="width: 100%;"
                                                @change="cropsChanged"
                                            >
                                                <a-select-option v-for="item in filteredCrops" :key="item" :value="item">
                                                {{ item }}
                                                </a-select-option>
                                            </a-select>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <h5>Reservoirs</h5>
                                        <div> 
                                            <a-select
                                                mode="multiple"
                                                placeholder="Select Reservoirs to include"
                                                :value="selectedReservoirs"
                                                style="width: 100%;"
                                                @change="reservoirsChanged"
                                            >
                                                <a-select-option v-for="item in filteredReservoirs" :key="item" :value="item">
                                                {{ item }}
                                                </a-select-option>
                                            </a-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-4">
                                <h5>Date Range</h5>
                                <div class="row">
                                    <div class="col-6"> 
                                        <a-date-picker placeholder="Start Date" style="width:100%" v-model="startDate" @change="getFilteredData"></a-date-picker>
                                    </div>
                                    <div class="col-6"> 
                                        <a-date-picker placeholder="End Date" style="width:100%" v-model="endDate" @change="getFilteredData"></a-date-picker>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row"  v-if="summaries.length" >
            <div class="col-12">
               <div class="card">
                   <div class="card-inner">
                       <table  class="table table-striped">
                   <thead>
                       <th>Year</th>
                       <th>MM Applied</th>
                       <th>Area</th>
                       <th>Gallons Used</th>
                       <th>Cubic Mtrs</th>
                       <th>Cost</th>
                       <th>Pull Time</th>
                   </thead>
                   <tbody> 
                       <tr v-for="summary in summaries" :key="summary.year">  
                            <td>{{summary.year}}</td>
                            <td>{{summary.mmApplied}}</td>
                            <td>{{summary.area}}</td>
                            <td>{{summary.gallonsUsed}}</td>
                            <td>{{summary.cubicMeters}}</td>
                            <td>&#163;{{summary.cost}}</td>
                            <td>{{summary.pullTime}}</td>
                            <td>{{summary.pullTimeDebug}}</td>
                       </tr>
                       <tr class="bg-dark text-white"> 
                           <td>Total</td>
                           <td>{{totals.mmApplied}}</td>
                           <td>{{totals.area}}</td>
                           <td>{{totals.gallonsUsed}}</td>
                           <td>{{totals.cubicMeters}}</td>
                           <td>&#163;{{totals.cost}}</td>
                           <td>{{totals.pullTime}}</td>
                       </tr>
                   </tbody>
               </table>
                   </div>
               </div>
            </div> 
        </div>
         <div class="row"  v-if="summaries.length" >
             <div class="col">

             </div>
             <div class="col-auto">
                 <download-csv
                    :data= "downloadableData">
                                 <div style="cursor:pointer;" class="preview-icon-box card card-bordered">
                     <div class="preview-icon-wrap">
                         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72"><path d="M50,61H22a6,6,0,0,1-6-6V22l9-11H50a6,6,0,0,1,6,6V55A6,6,0,0,1,50,61Z" style="fill:#36c684"></path><path d="M25,20.556A1.444,1.444,0,0,1,23.556,22H16l9-11h0Z" style="fill:#95e5bd"></path><path d="M42,31H30a3.0033,3.0033,0,0,0-3,3V45a3.0033,3.0033,0,0,0,3,3H42a3.0033,3.0033,0,0,0,3-3V34A3.0033,3.0033,0,0,0,42,31ZM29,38h6v3H29Zm8,0h6v3H37Zm6-4v2H37V33h5A1.001,1.001,0,0,1,43,34ZM30,33h5v3H29V34A1.001,1.001,0,0,1,30,33ZM29,45V43h6v3H30A1.001,1.001,0,0,1,29,45Zm13,1H37V43h6v2A1.001,1.001,0,0,1,42,46Z" style="fill:#fff"></path></svg></div><h3><h5>Download Report</h5></h3></div>
            </download-csv>

             </div>
             <div class="col">

             </div>
         </div>
    </div>
</template>
<script>
import { VueCsvImport } from "vue-csv-import";

export default { 
 computed: {
    filteredSites() {
      return this.sites.filter(o => !this.selectedSites.includes(o));
    },
    filteredCrops() {
      return this.crops.filter(o => !this.selectedCrops.includes(o));
    },
    filteredReservoirs() {
      return this.reservoirs.filter(o => !this.selectedReservoirs.includes(o));
    },
  },
  created() {
        //this.processData()
  },
  components: {
    VueCsvImport,
  },
  methods:{ 
      resetPage(){
        this.summaries = [],
        this.totals = {'mmApplied': '','area': '','gallonsUsed': '','cubicMeters': '', 'cost': '' , 'pullTime': ''},
        this.selectedCrops = [],
        this.selectedSites =  [],
        this.selectedReservoirs =  [],
        this.sites = [],
        this.crops = [],
        this.years = [],
        this.reservoirs = [],
        this.variableValues = {gallonsUsed: 222, cubicMeters: 0.60, av_used_per_day: 30, areaReducer: 10000},
        this.filteredData =  [], 
        this.baseData =  []
      },
      cleanseFields(){
          this.baseData.forEach(element => {
              element["mmApplied"] = element['MM Applied']
                delete element['MM Applied'];
              element["gallonsUsed"] = element['Gallons Used']
                delete element['Gallons Used'];
              element["mmHa"] = element['MM/HA']
                delete element['MM/HA'];
              element["pullTime"] = element['Pull Time']
                delete element['Pull Time'];
              element["boomWidth"] = element['Boom Width']
                delete element['Boom Width'];
              element["pullLength"] = element['Pull Length']
                delete element['Pull Length'];
              element["cubicMeters"] = element['Pull Length']
                delete element['Pull Length'];
          })
      },
      sitesChanged(selectedSites){
         this.selectedSites = selectedSites;
         this.getFilteredData()
      },
      cropsChanged(selectedCrops){
         this.selectedCrops = selectedCrops;
         this.getFilteredData()
      },
      reservoirsChanged(selectedReservoirs){
         this.selectedReservoirs = selectedReservoirs;
         this.getFilteredData()
      },
      roundToxDec(n, dp){ 
        const h = +('1'.padEnd(dp + 1, '0')) // 10 or 100 or 1000 or etc
        return Math.round(n * h) / h 
      },
      calculateSummary(){ 
          this.summaries = []
          this.years.forEach(year => {
              let summary = {'year': year, 'mmApplied': '','area': '','gallonsUsed': '','cubicMeters': '', 'cost': '' , 'pullTime': ''}
              let sumArr = this.filteredData.filter(x => x.Date.slice(-4) === year)  
              summary.year = year
              summary.mmApplied = sumArr.reduce((n, {mmApplied}) => n + parseInt(mmApplied), 0)
              summary.area = Math.round(sumArr.reduce((n, {Area}) => n + parseInt(Area), 0) / this.variableValues.areaReducer)
              summary.gallonsUsed = sumArr.reduce((n, {gallonsUsed}) => n + parseInt(gallonsUsed), 0)
              summary.cubicMeters = Math.round(sumArr.reduce((n, {gallonsUsed}) => n + parseInt(gallonsUsed), 0) / this.variableValues.gallonsUsed)
              summary.cost =  Math.round((sumArr.reduce((n, {gallonsUsed}) => n + parseInt(gallonsUsed), 0) / this.variableValues.gallonsUsed) * this.variableValues.cubicMeters)
              summary.pullTime = this.roundToxDec(sumArr.reduce((n, {pullTime}) => isNaN(n + parseFloat(pullTime)) ? 0 : n + parseFloat(pullTime), 0),2)
              this.summaries.push(summary)
              
          }); 
          this.calculateTotals()
      },
      calculateTotals(){ 
          this.totals.mmApplied = this.summaries.reduce((n, {mmApplied}) => n + parseInt(mmApplied), 0)
          this.totals.area = this.summaries.reduce((n, {area}) => n + parseInt(area), 0)
          this.totals.gallonsUsed = this.summaries.reduce((n, {gallonsUsed}) => n + parseInt(gallonsUsed), 0)
          this.totals.cubicMeters = this.summaries.reduce((n, {cubicMeters}) => n + parseInt(cubicMeters), 0)
          this.totals.cost = this.summaries.reduce((n, {cost}) => n + parseInt(cost), 0)
          this.totals.pullTime = this.roundToxDec(this.summaries.reduce((n, {pullTime}) => n + parseFloat(pullTime), 0),2)

          this.setupDownloadData()
      },
      setupDownloadData(){
          this.downloadableData = []
          this.downloadableData = JSON.parse(JSON.stringify(this.summaries))
          this.downloadableData.push(this.totals)
      },
      getFilteredData(){
          // filter out based on sites
          if (this.selectedSites.length){
                this.filteredData = this.baseData.filter(x => this.selectedSites.includes(x.Field))
          } else {
                this.filteredData = this.baseData
          }
          // now filter out based on crops
          if (this.selectedCrops.length){
                this.filteredData = this.filteredData.filter(x => this.selectedCrops.includes(x.Crop))
          } 

          // now filter out based on reservoirs
          if (this.selectedReservoirs.length){
                this.filteredData = this.filteredData.filter(x => this.selectedReservoirs.includes(x.Reservoir))
          } 

          // now filter out based on date range
          if (this.startDate){
                this.filteredData = this.filteredData.filter(x => this.$moment(x.Date) >= this.startDate)
          }

          // now filter out based on date range
          if (this.endDate){
                this.filteredData = this.filteredData.filter(x => this.$moment(x.Date) <= this.endDate)
          } 

           this.calculateSummary()
      },
      processIRData(){ 
          this.cleanseFields()
          this.getSitesAndCrops()
          this.getFilteredData()
          this.calculateSummary()
      },
      getSitesAndCrops(){
          this.sites = Object.keys(this.baseData.reduce((r,{Field}) => (r[Field]='', r) , {}))
          this.reservoirs = Object.keys(this.baseData.reduce((r,{Reservoir}) => (r[Reservoir]='', r) , {}))
          this.crops = Object.keys(this.baseData.reduce((r,{Crop}) => (r[Crop]='', r) , {}))
          this.years = Object.keys(this.baseData.reduce((r,{Date}) => (r[Date.slice(-4)]='', r) , {}))
      }
  },
  data() {
    return {
      downloadableData:[],
      summaries:[],
      totals:{'mmApplied': '','area': '','gallonsUsed': '','cubicMeters': '', 'cost': '' , 'pullTime': ''},
      selectedCrops:[],
      selectedSites: [],
      sites:[],
      crops:[],
      years:[],
      variableValues:{gallonsUsed: 222, cubicMeters: 0.60, av_used_per_day: 30, areaReducer: 10000},
      filteredData: [], 
      baseData: [],
      selectedReservoirs: [],
      reservoirs: [],
      startDate: null,
      endDate: null,
    };
  },
};
</script>
<style>
    
</style>